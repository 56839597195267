import React, { Component } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import '../styles/main.scss'
import ClientsBox from '../components/index-page/clients-box'
import AnimateComponent from '../components/animate-component'
import {graphql} from "gatsby";

class Klienci extends Component {

    componentDidMount() {
        // const pagination = require('../js/pagination');
    }

    render() {
        const posts = this
            .props
            .data
            .wordpressPage
            .acf
    return (

            <Layout>
                <SEO title="Klienci | Świecki Kancelaria"/>
                <AnimateComponent styleName="clients-page" id="clients-page" tag="section">
                    <div className="clients-page__container">

                        <header className="clients-page__header wow zoomIn">
                            <div className="clients-page__title-container">
                                <h1>
                                    Nasi klienci
                                </h1>
                            </div>
                        </header>

                        <div className="clients-page__clients-list">
                            {console.log(posts.klienci_repeater)}
                            {posts.klienci_repeater
                                .map((el, i) => {
                                    return (<ClientsBox
                                        key={i}
                                        logo={el?.logo?.localFile?.publicURL}
                                        boxClass='clients-page__client'
                                        imgClass='clients-page__photo'/>)
                                })}
                        </div>
                        {/* <button className="clients-page__button" id="load-more">Załaduj więcej</button> */}
                    </div>
                </AnimateComponent>
            </Layout>
        )

    }
}

export default Klienci

export const pageQuery = graphql`
    query {
        wordpressPage( slug: {eq: "home"}) {
            id
            title
            content
            excerpt
            date
            modified
            slug
            status
            acf {
              klienci_repeater {
                logo {
                  localFile {
                    publicURL
                  }
                }
              }
            }
        }


    }
`